<template>
  <b-navbar
    v-b-scrollspy="{ method: 'auto' }"
    variant="faded"
    type="dark"
    fixed="top"
    toggleable="md"
  >
    <b-navbar-brand :to="brand.link">
      <img
        :src="brand.logo"
        alt="logo"
        class="d-inline-block align-center"
        height="50"
      />
    </b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item :to="brand.link">
        <h5>{{ brand.name }}</h5>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-toggle target="nav-collapse">
      <template>
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </template>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-for="item in menu"
          :key="item.id"
          :to="item.link"
           @click="scrollTo(item.link)"
        >
          {{ item.label }}</b-nav-item
        >
        <b-nav-item :href="social.insta" target="/blank"
          ><i class="fab fa-instagram"></i
        ></b-nav-item>
        <b-nav-item :href="social.linkedin" target="/blank"
          ><i class="fab fa-linkedin-in"></i
        ></b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      brand: {
        name: '',
        logo: '',
        link: ''
      },
      menu: [],
      social: {
        insta: '',
        linkedin: ''
      }
    }
  },
  mounted () {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }
    this.changeData(require('@/data/content_navbar.json'))
  },
  methods: {
    scrollTo: function (hashtag) {
      setTimeout(() => {
        location.href = hashtag
      }, 1)
    },
    changeData: function (data) {
      (this.brand = data.brand)
      for (let i = 0; i < data.menu.length; i++) {
        const item = data.menu[i]
        item.id = i
        this.menu.push(item)
      }
      this.social = data.social
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h5 {
  color: #00ffd6;
}
.navbar {
  background-color: #00060c;
}
.navbar-dark .navbar-brand {
  color: #00ffd6 !important;
  &:active,
  &:focus {
    outline: 0;
  }
}
.navbar-dark .navbar-nav .nav-link {
  &:active,
  &:focus {
    outline: 0;
    color: #00ffd6 !important;
  }
  color: #ffffffff !important;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff88 !important;
}
.navbar-dark .navbar-toggler {
  border: none;
  background: transparent !important;

  /* remove the blue outline when active or focused */
  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    background: transparent !important;
  }

  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
    background: #ffffff;
  }
}
.not-collapsed {
  .top-bar {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
  }
}
.collapsed {
  .top-bar {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}
</style>
