/* jshint esversion: 6 */

import * as THREE from '../../node_modules/three/build/three.module.js'

import {
  OrbitControls
} from '../../node_modules/three/examples/jsm/controls/OrbitControls.js'

// import Stats from '../../node_modules/three/examples/jsm/libs/stats.module.js'

import { EffectComposer } from '../../node_modules/three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from '../../node_modules/three/examples/jsm/postprocessing/RenderPass.js'
import { AfterimagePass } from '../../node_modules/three/examples/jsm/postprocessing/AfterimagePass.js'

import { GlitchPass } from './custom_glitchPass.js'

import { ShaderPass } from '../../node_modules/three/examples/jsm/postprocessing/ShaderPass.js'
import { BloomPass } from '../../node_modules/three/examples/jsm/postprocessing/BloomPass.js'
import { FilmPass } from '../../node_modules/three/examples/jsm/postprocessing/FilmPass.js'
// import { DotScreenPass } from '../../node_modules/three/examples/jsm/postprocessing/DotScreenPass.js';
// import { MaskPass, ClearMaskPass } from '../../node_modules/three/examples/jsm/postprocessing/MaskPass.js';
// import { TexturePass } from '../../node_modules/three/examples/jsm/postprocessing/TexturePass.js';

// import { BleachBypassShader } from '../../node_modules/three/examples/jsm/shaders/BleachBypassShader.js';
// import { ColorifyShader } from '../../node_modules/three/examples/jsm/shaders/ColorifyShader.js';
import { HorizontalBlurShader } from '../../node_modules/three/examples/jsm/shaders/HorizontalBlurShader.js'
import { VerticalBlurShader } from '../../node_modules/three/examples/jsm/shaders/VerticalBlurShader.js'
// import { SepiaShader } from '../../node_modules/three/examples/jsm/shaders/SepiaShader.js';
import { VignetteShader } from '../../node_modules/three/examples/jsm/shaders/VignetteShader.js'
// import { GammaCorrectionShader } from '../../node_modules/three/examples/jsm/shaders/GammaCorrectionShader.js';

/// /////////////////////////
//
// keep your hands off everything above
//
// Setup model below
//
/// /////////////////////////

/* background setup: color names or hex codes (0xff0000) -> red */
const backgroundColor = '#00060c'

const exposure = 1

const enablePostproc = true

/* debug helper */
// let logModelChildNames = true;

/// /////////////////////////
//
// Setup model above
//
// keep your hands off everything that follows below
//
/// /////////////////////////

let scene, camera, renderer, composer, controls, afterimagePass, glitchPass//, stats

let canvas, container

let routeActivated = false
// var cameraCenter = new THREE.Vector3();
// var cameraHorzLimit = 0.001;
// var cameraVertLimit = 0.001;
var mouse = new THREE.Vector2()

const menuOffset = 76

const lineCubesList = []

// let orbitChanged = true;

function setUpRender () {
  canvas = document.querySelector('#canvas-section-1')
  container = document.querySelector('#container-section-1-bg')
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true
  })

  renderer.autoClear = false

  renderer.setSize(canvas.width, canvas.height)

  renderer.toneMapping = THREE.ReinhardToneMapping
  renderer.toneMappingExposure = exposure

  renderer.outputEncoding = THREE.sRGBEncoding

  // stats = new Stats()
  // container.appendChild(stats.dom)
};

function setUpCamera () {
  const fov = 60
  const aspect = canvas.width / canvas.height
  const near = 0.1
  const far = 15000

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
  camera.lookAt(new THREE.Vector3(0, 0, 0))
  camera.position.z = 2

  // cameraCenter.x = camera.position.x;
  // cameraCenter.y = camera.position.y;
  // cameraCenter.z = camera.position.z;
}

function setUpThreeScene () {
  scene = new THREE.Scene()
  scene.background = new THREE.Color(backgroundColor)
  const color = 0x00060c
  const density = 0.3
  scene.fog = new THREE.FogExp2(color, density)
  scene.add(camera)
  // scene.add(textureCamera);
};

function setUpControls () {
  // const controls = new OrbitControls(camera, renderer.domElement);
  controls = new OrbitControls(camera, canvas)

  controls.enableZoom = false // Zooming
  controls.enablePan = false
  controls.screenSpacePanning = false
  controls.enableRotate = false
  controls.autoRotate = true // enable rotation
  controls.enableDamping = true
  controls.dampingFactor = 0.25
  controls.maxPolarAngle = Math.PI / 2 // Limit angle of visibility
  // controls.addEventListener('change', onOrbitChanged);

  return controls
}

function addModelToScene () {
  const geometry = new THREE.BoxGeometry(1, 1, 1)
  const color = 0xffffff

  function makeInstance (scale) {
    const edges = new THREE.EdgesGeometry(geometry, 1)
    const linecube = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
      color: color,
      linewidth: 5
    }))
    scene.add(linecube)

    const mat = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.03,
      map: createDot()
    })

    const pointies = new THREE.Points(geometry, mat)
    scene.add(pointies)

    // linecube.position.y =.0 ;
    // pointies.position.y =.05 ;
    pointies.scale.set(scale.x, scale.y, scale.z)
    linecube.scale.set(scale.x, scale.y, scale.z)

    linecube.points = pointies

    lineCubesList.push(linecube)
  }

  for (let i = 0; i <= 2; ++i) {
    makeInstance(new THREE.Vector3(0.8 - (0.8 * (i / 2)), 0.8, 0.8))
    makeInstance(new THREE.Vector3(1.0 - (1.0 * (i / 2)), 1.0, 1.0))
    makeInstance(new THREE.Vector3(1.2 - (1.2 * (i / 2)), 1.2, 1.2))
    // makeInstance(new THREE.Vector3(0.8, 0.8,0.8 - (0.8 * (i/2)))),
    // makeInstance(new THREE.Vector3(1.0, 1.0, 1.0 - (1.0 * (i/2)))),
    // makeInstance(new THREE.Vector3(1.2, 1.2, 1.2 - (1.2 * (i/2)))),
  }
};

// draw a circle
function createDot () {
  const canvas = document.createElement('canvas')
  canvas.width = 32
  canvas.height = 32
  const ctx = canvas.getContext('2d')
  ctx.beginPath()
  ctx.arc(16, 16, 16, 0, Math.PI * 2)
  ctx.fillStyle = 'white'
  ctx.fill()
  return new THREE.CanvasTexture(canvas)
}

async function setupUpPostProcessing () {
  // // const shaderBleach = BleachBypassShader;
  // // const shaderSepia = SepiaShader;
  // const shaderVignette = VignetteShader;

  // // const effectBleach = new ShaderPass(shaderBleach);
  // // const effectSepia = new ShaderPass(shaderSepia);
  // const effectVignette = new ShaderPass(shaderVignette);
  // // const gammaCorrection = new ShaderPass(GammaCorrectionShader);
  // // effectBleach.uniforms["opacity"].value = 0.95;

  // // effectSepia.uniforms["amount"].value = 0.9;

  // effectVignette.uniforms["offset"].value = .95;
  // effectVignette.uniforms["darkness"].value = 1.6;

  // const effectBloom = new BloomPass(1.5);
  // const effectFilm = new FilmPass(0.35, 1.025, 648, false);
  // // const effectFilmBW = new FilmPass(.35, 10.5, 2048, true);
  // // const effectDotScreen = new DotScreenPass(new THREE.Vector2(0, 0), 0.5, 0.8);

  // const effectHBlur = new ShaderPass(HorizontalBlurShader);
  // const effectVBlur = new ShaderPass(VerticalBlurShader);
  // effectHBlur.uniforms['h'].value = .1 / (canvas.width / 2);
  // effectVBlur.uniforms['v'].value = .1 / (canvas.height / 2);

  // // const effectColorify1 = new ShaderPass(ColorifyShader);
  // // const effectColorify2 = new ShaderPass(ColorifyShader);
  // // effectColorify1.uniforms['color'] = new THREE.Uniform(new THREE.Color(1, 0.8, 0.8));
  // // effectColorify2.uniforms['color'] = new THREE.Uniform(new THREE.Color(1, 1, 1));

  // composer = new EffectComposer(renderer);
  // composer.addPass(new RenderPass(scene, camera));
  // // composer.addPass(effectDotScreen);

  // composer.addPass(effectBloom);
  // composer.addPass(effectFilm);

  // afterimagePass = new AfterimagePass();
  // afterimagePass.uniforms["damp"].value = 0.85
  // composer.addPass(afterimagePass);

  composer = new EffectComposer(renderer)
  // const imageoverlay =
  new THREE.TextureLoader().load('./img/logo_transparent_pure_weiss.png', imageoverlay => {
    // console.log(imageoverlay.image.width)

    const shaderVignette = VignetteShader

    const effectVignette = new ShaderPass(shaderVignette)
    effectVignette.uniforms.offset.value = 0.95
    effectVignette.uniforms.darkness.value = 1.6

    const effectBloom = new BloomPass(1.5)
    const effectFilm = new FilmPass(0.35, 1.025, 648, false)

    const effectHBlur = new ShaderPass(HorizontalBlurShader)
    const effectVBlur = new ShaderPass(VerticalBlurShader)
    effectHBlur.uniforms.h.value = 0.1 / (canvas.width / 2)
    effectVBlur.uniforms.v.value = 0.1 / (canvas.height / 2)

    composer.addPass(new RenderPass(scene, camera))

    composer.addPass(effectBloom)
    composer.addPass(effectFilm)

    afterimagePass = new AfterimagePass()
    afterimagePass.uniforms.damp.value = 0.85
    composer.addPass(afterimagePass)

    glitchPass = new GlitchPass(imageoverlay, canvas)
    composer.addPass(glitchPass)

    composer.addPass(effectHBlur)
    composer.addPass(effectVBlur)

    composer.addPass(effectVignette)
  })
  //  let width = imageoverlay.width;

  //  console.log(imageoverlay.image)
  // imageoverlay.wrapS = imageoverlay.wrapT = THREE.MirroredRepeatWrapping;
  // imageoverlay.repeat.set( 5, 5 );

  // composer.addPass( gammaCorrection );
  // composer.addPass(effectDotScreen);
  // composer.addPass( renderMask );
  // composer.addPass( effectColorify2 );
  // composer.addPass( clearMask );
  // composer.addPass( renderMaskInverse );
  // composer.addPass( effectColorify2 );
  // composer.addPass( clearMask );
  // composer.addPass( effectBleach );
  // composer.addPass( effectSepia );
  // composer.addPass( effectFilmBW );
  // composer.addPass( effectColorify2 );
}

function onWindowResize () {
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight

  camera.aspect = canvas.width / canvas.height

  camera.updateProjectionMatrix()

  renderer.setSize(canvas.width, canvas.height)
}

function onRouteActivated () {
  if (controls) {
    controls.reset()
  }
  routeActivated = true
  // console.log('sec 1 activated');
  requestAnimationFrame(render)
}
function onRouteDeactivated () {
  routeActivated = false
  // console.log('sec 1 deactivated');
}

function onPointerMove (event) {
  if (event.isPrimary === false) return

  mouse.x = event.clientX - canvas.width / 2
  mouse.y = event.clientY - canvas.height / 2

  // console.log(event.clientX)
  event.preventDefault()
}

// function updateCamera() {
//     //offset the camera x/y based on the mouse's position in the window
//     camera.position.x = cameraCenter.x + (cameraHorzLimit * mouse.x);

//     camera.position.y = cameraCenter.y + (cameraVertLimit * mouse.y);

//     // console.log(camera.position)
// }

function updateCubeRoation () {
  // lineCubesList.forEach(cube => {

  //     cube.rotation.y = mouse.x / 100;
  //     cube.points.rotation.y = cube.rotation.y;

  // });

  for (let i = 0; i < lineCubesList.length; i++) {
    const cube = lineCubesList[i]
    cube.rotation.y = mouse.x / 3000 * i
    cube.points.rotation.y = cube.rotation.y
    cube.rotation.x = mouse.y / 5000 * i
    cube.points.rotation.x = cube.rotation.x
  }
}

function render (time) {
  time *= 0.001 // convert time to seconds

  // console.log(time);
  // updateCamera();
  updateCubeRoation()
  if (enablePostproc && composer) {
    composer.render()
  } else {
    renderer.render(scene, camera)
  }
  // stats.update()
  // orbitChanged = false;
  controls.update()
  if (routeActivated) {
    requestAnimationFrame(render)
  }
};

(async function main () {
  setUpRender()

  setUpCamera()

  setUpThreeScene()

  setUpControls(camera, renderer)

  setupUpPostProcessing()

  window.addEventListener('resize', onWindowResize, false)
  window.addEventListener('Sec1_routeActivated', onRouteActivated, false)
  window.addEventListener('Sec1_routeDeactivated', onRouteDeactivated, false)
  document.body.addEventListener('pointermove', onPointerMove, false)
  await addModelToScene()

  onWindowResize()
  // routeActivated = true;
  // requestAnimationFrame(render)
}())
