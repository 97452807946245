<template>
  <section id="section-4">
    <div id="section-4-content-1" >
      <h3>{{ title }}</h3>
      <p>{{ maintext }}</p>
      <br />
      <br />
    </div>
      <Reference3 class="gallery"/>

    <div id="section-4-content-2" >
      <div>
        <b-card-group deck class="justify-content-center">
          <b-card
            v-for="reference in references"
            :key="reference.id"
            no-body
            tag="article"
            style="max-width: 20rem; min-width: 18rem"
            class="mb-4 border-0"
          >
            <b-card-img
              :src="reference.img"
              alt="Image"
              class="rounded-top"
            ></b-card-img>
            <b-card-body :title="reference.title">
              <br />
              <b-button :to="reference.to" variant="light"
                >{{ reference.buttonlabel }}
                <b-icon
                  icon="chevron-right"
                  aria-hidden="true"
                  font-scale="0.95"
                  shift-v="1"
                ></b-icon
              ></b-button>
            </b-card-body>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </section>
</template>

<script>
import Reference3 from '../views/Reference3.vue'
export default {
  name: 'Section4',
  components: { Reference3 },
  mounted () {
    this.changeData(require('@/data/content_section4.json'))
  },
  data () {
    return {
      references: [],
      title: '',
      maintext: ''
    }
  },
  methods: {
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
      for (let i = 0; i < data.references.length; i++) {
        const item = data.references[i]
        item.id = i
        this.references.push(item)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-4{
  background-image: url("../assets/textures/Raster_blue_smaller.webp");
  min-height: 100vh;
  z-index: 10;
  margin: auto;
  width: 100%;
  top: 50%;
}
#section-4-content-1,
#section-4-content-2 {
  padding: 0;
  padding-left: 15%;
  padding-right: 15%;
}
#section-4-content-1 {
  padding-top: 10%;
}

#section-4-content-2 {
  padding-top: 5%;
  padding-bottom: 5%;
}
.gallery{
  padding: 0;
  transition: height 3s ease-in-out;
}

h3 {
  font-style: italic;
  text-align: left;
}
.card {
  background-color: #353535;
  font-style: italic;
  color: #00ffd6;
  border-radius: 1.5%;
}
.rounded-top {
  border-radius: 0%;
  border-top-left-radius: 1.5% !important;
  border-top-right-radius: 1.5% !important;
}
.btn .btn-light {
  border: none !important;

  outline: none !important;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
  }
}
.btn .btn-light:hover {
  color: #212529;
  background-color: #00ffd6;
}
// .fade-enter-active,
// .fade-leave-active {
//   transition-delay: 1s;
//   transition: all 0.5s ease;
//   transform: translateY(100px);
// }

// .fade-enter,
// .fade-leave-to {
//   opacity: 0;

//   // transform: translateY(0px);
// }
</style>
