<template>
  <section id="section-6">
    <div id="section-6-separator"></div>
    <div id="section-6-bg" v-rellax="{ speed: -4, center: true }"></div>
    <div id="section-6-content">
      <div id="text-backdrop">
        <h3>{{ title }}</h3>
        <p v-html="maintext"></p>
        <br />
        <b-link :href="mailaddresse">
          <h4>{{ maillabel }}</h4>
        </b-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section6',
  mounted () {
    this.changeData(require('@/data/content_section6.json'))
  },
  data () {
    return {
      title: '',
      maintext: '',
      mailaddresse: '',
      maillabel: ''
    }
  },
  methods: {
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
      this.mailaddresse = data.mailaddresse
      this.maillabel = data.maillabel
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-6 {
  background-color: #00060c;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
#section-6-bg {
  background-image: url("../assets/textures/section-6-bg.webp");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 9;
}
#text-backdrop {
  background-color: rgba(0, 6, 12, 0.6);
  width: 95%;
  position: relative;
  margin-left: 15%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 30%;
}
#section-6-content {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
  top: 50%;
  padding-top: 15%;
  padding-bottom: 15%;
  margin-top: 15%;
  margin-bottom: 15%;
}
#section-6-separator {
  background-image: url("../assets/textures/Raster_blue_smaller.webp");
  height: 40vh;
  position: relative;
  z-index: 10;
}
h3 {
  font-style: italic;
  text-align: left;
}
a {
  color: white !important;
  border: none !important;
  text-decoration: none;
  text-align: left;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
    color: #ffffff77 !important;
    text-decoration: none;
  }
}
</style>
