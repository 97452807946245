<template>
  <section id="section-1">
    <div id="container-section-1-bg" v-rellax="{ speed: -4, center: true}">
      <canvas id="canvas-section-1"></canvas>
    </div>
    <div id="section-1-content" >
      <h1>{{ title }}</h1>
      <h3>{{ maintext }}</h3>
      <b-nav>
        <b-nav-item :to="to" @click="scrollTo(to)" >
          <h4>
            {{ linklabel }}
            <b-icon icon="chevron-down" aria-hidden="true"></b-icon>
          </h4>
        </b-nav-item>
      </b-nav>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section1',
  mounted () {
    require('@/scripts/section1_3dcontent_bg.js')

    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }

    this.changeData(require('@/data/content_section1.json'))
  },
  activated () {
    const event = new Event('Sec1_routeActivated')
    window.dispatchEvent(event)
  },
  deactivated () {
    const event = new Event('Sec1_routeDeactivated')
    window.dispatchEvent(event)
  },
  data () {
    return {
      title: '',
      maintext: '',
      linklabel: '',
      to: ''
    }
  },
  methods: {
    scrollTo: function (hashtag) {
      setTimeout(() => { location.href = hashtag }, 1)
    },
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
      this.linklabel = data.linklabel
      this.to = data.to
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-1 {
  background-color: #00060c;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
#container-section-1-bg {
  width: 100vw;
  height: calc(100vh - 76px);
  position: absolute;
  top: 0;
}
#canvas-section-1 {
  left: 0px;
  position: absolute;
  pointer-events: all;
}
#section-1-content {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
  pointer-events: all;
  height: calc(100vh - 76px);
  display: grid;
  align-content: center;
}
.nav-item {
  margin: auto;
}
.nav-item .nav-link {
  color: white !important;
  border: none !important;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
    color: #ffffff77 !important;
  }
}
.center {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
