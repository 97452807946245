<template>
  <div class="home">
    <div id="sec1">
      <section-1 />
    </div>
    <div id="sec2">
      <section-2 />
    </div>
    <div id="sec3">
      <section-3 />
    </div>
    <div id="sec4">
      <section-4 />
    </div>
    <div id="sec5">
      <section-5 />
    </div>
    <div id="sec6">
      <section-6 />
    </div>
    <div id="foot">
      <footer-contact />
    </div>
    <div id="back-to-top-button" class="right-bottom opacity-zero">
      <b-button
        variant="primary"
        id="back-to-top"
        class="rounded-circle px-2"
        @click="scrollToTop"
      >
        <b-icon icon="chevron-up" scale="1"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Section1.vue'
import Section2 from '@/components/Section2.vue'
import Section3 from '@/components/Section3.vue'
import Section4 from '@/components/Section4.vue'
import Section5 from '@/components/Section5.vue'
import FooterContact from '@/components/Footer-Contact.vue'
import Section6 from '@/components/Section6.vue'
import store from '../store'

export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    FooterContact,
    Section6
  },
  data () {
    return {
      active: false
    }
  },
  mounted () {
    const that = this
    document.addEventListener('scroll', function (e) {
      // console.log(window.scrollY , window.innerHeight);
      if (that.active) {
        if (window.scrollY > window.innerHeight / 3) {
          document
            .getElementById('back-to-top-button')
            .classList.remove('opacity-zero')
        } else {
          document
            .getElementById('back-to-top-button')
            .classList.add('opacity-zero')
        }
      }

      const observer = new IntersectionObserver(
        function (entries, observer) {
          try {
            if (that.active) {
              // console.log("that.active");
              entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
                  // console.log(entry.target.id, entry.intersectionRatio);

                  store.dispatch('setActiveHomeViewHash', entry.target.id)
                }
              })
            }
          } catch (error) {
            // console.log(error);
          }
        },
        { threshold: [0.5] }
      )
      try {
        observer.observe(document.querySelector('#section-1'))
        observer.observe(document.querySelector('#section-2'))
        observer.observe(document.querySelector('#section-3'))
        observer.observe(document.querySelector('#section-4'))
        observer.observe(document.querySelector('#section-5'))
        observer.observe(document.querySelector('#section-6'))
        observer.observe(document.querySelector('#footer-content'))
      } catch (error) {
        // console.log(error);
      }
    })

    // this.active = true;
    // console.log("mounted", this.active)
  },
  activated () {
    this.active = true
    // console.log("activated", this.active)
  },
  deactivated () {
    this.active = false
    // console.log("deactivated", this.active)
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style scoped lang="scss">
#back-to-top {
  pointer-events: auto;
}
.right-bottom {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 250;
  justify-content: flex-end;
}
.opacity-zero {
  opacity: 0;

  transition: all 0.5s !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
#sec1,
#sec2,
#sec3,
#sec4,
#sec5,
#sec6,
#foot {
  position: relative;
}
#sec1 {
  height: calc(100vh - 76px);
}
#sec2 {
  z-index: 15;
}
#foot {
  z-index: 30;
}
</style>
