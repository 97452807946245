<template>
  <section id="section-3">
    <div
      id="section-3-bg"
      v-rellax="{ speed: -4, center: true }"
    ></div>
    <div id="section-3-content">
      <div id="text-backdrop">
        <h3>{{ title }}</h3>
        <p v-html="maintext"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section3',
  mounted () {
    this.changeData(require('@/data/content_section3.json'))
  },
  data () {
    return {
      title: '',
      maintext: ''
    }
  },
  methods: {
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-3 {
  background-color: #00060c;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
#section-3-bg {
  background-image: url("../assets/textures/section-3-bg.webp");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
}
#text-backdrop {
  background-color: rgba(0, 6, 12, 0.6);
  width: 90%;
  position: relative;
  margin-right: 15%;
  margin-left: 0%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 25%;
  padding-right: 5%;
}
#section-3-content {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
  top: 50%;
  padding-top: 25%;
  padding-bottom: 25%;
}
h3 {
  font-style: italic;
  text-align: left;
}
.center {
  min-height: 100%;
  display: block;
}
</style>
