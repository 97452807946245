import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'

// import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: {hash: store.state.activeHomeViewHash},
    component: Home
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/reference1',
    name: 'Reference1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reference1" */ '../views/Reference1.vue')
  },
  {
    path: '/reference2',
    name: 'Reference2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reference2" */ '../views/Reference2.vue')
  },
  // {
  //   path: '/reference3',
  //   name: 'Reference3',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "reference3" */ '../views/Reference3.vue')
  // },
  {
    path: '/reference4',
    name: 'Reference4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reference4" */ '../views/Reference4.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '*',
    name: 'Error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue')
  }
]

const router = new VueRouter({
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      // console.log("router has hash")
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      // console.log("router has savedPosition")
      return {
        savedPosition
      }
    } else {
      // console.log("router has nothing")
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// removed Login
// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page

//   const publicPages = ['/login']
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = sessionStorage.getItem('user')

//   if (authRequired && !loggedIn) {
//     return next({
//       path: '/login',
//       query: { returnUrl: to.path }
//     })
//   }

//   // if(from.name !== "Home" && to.name === "Home"){

//   //   console.log(to)
//   //   // let tempTo = Object.assign({},to);
//   //   // tempTo.hash = store.state.activeHomeViewHash
//   //   // tempTo.fullPath = "/" + store.state.activeHomeViewHash

//   //   // to = tempTo

//   //   router.push({hash: store.state.activeHomeViewHash})

//   //   console.log(to)

//   //   }

//   next()
// })

export default router
