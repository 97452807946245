import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentConfiguration: {
      selectedPartId: -1,
      colors: [],
      addons: []
    },
    startprice: 2999,
    price: 0,
    activeHomeViewHash: ''
  },
  mutations: {
    m_setAddonState (state, addonState) {
      // console.log(addonState.id, addonState.state)
      // console.log(state.currentConfiguration.addons)
      state.currentConfiguration.addons[addonState.id].state = addonState.state
      // console.log(state.currentConfiguration.addons)
    },
    m_setPartColor (state, colorSetup) {
      state.currentConfiguration.colors[colorSetup.partid] = colorSetup.color
      // console.log(state.currentConfiguration.colors)
    },
    m_setPrice (state) {
      var tempPrice = Number(state.startprice)
      state.currentConfiguration.addons.forEach(addon => {
        if (addon.state) {
          tempPrice += Number(addon.extraCost)
          // console.log("addon", addon.extraCost, Number(addon.extraCost), state.currentConfiguration.addons)
        }
      })
      state.currentConfiguration.colors.forEach(color => {
        tempPrice += Number(color.extraCost)
      })

      state.price = tempPrice
      // console.log(state.price);
    },
    m_setSelectedPartId (state, partid) {
      state.currentConfiguration.selectedPartId = partid
      // console.log(state.currentConfiguration.selectedPartId)
    },
    m_setActiveHomeViewHash (state, hash) {
      state.activeHomeViewHash = hash
      // console.log("home view hash changed", hash)
    },

    // for initialization only!
    m_addAddon (state, addon) {
      state.currentConfiguration.addons.push(addon)
    },
    m_addColor (state, color) {
      state.currentConfiguration.colors.push(color)
    },
    m_resetAddons (state) {
      state.currentConfiguration.addons = []
    },
    m_resetColors (state) {
      state.currentConfiguration.colors = []
    }
  },
  actions: {
    setAddonState ({ commit }, addonState) {
      commit('m_setAddonState', addonState)
      commit('m_setPrice')
    },
    setPartColor ({ commit }, colorSetup) {
      commit('m_setPartColor', colorSetup)
      commit('m_setPrice')
    },
    setSelectedPartId ({ commit }, partid) {
      commit('m_setSelectedPartId', partid)
    },
    setActiveHomeViewHash ({ commit }, hash) {
      commit('m_setActiveHomeViewHash', hash)
    },

    // for initialization only!
    addAddon ({ commit }, addon) {
      commit('m_addAddon', addon)
      commit('m_setPrice')
    },
    addColor ({ commit }, color) {
      commit('m_addColor', color)
      commit('m_setPrice')
    },
    resetAddons ({ commit }) {
      commit('m_resetAddons')
    },
    resetColors ({ commit }) {
      commit('m_resetColors')
    }
  },
  modules: {}
})
