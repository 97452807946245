<template>
  <section id="section-2">
    <div id="section-2-content">
      <h3>{{ title }}</h3>
      <p v-html="maintext"></p>

      <!-- <video
        controls
        id="video-main"
        :poster="poster"
        autobuffer
      >
        <source :src="video" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video> -->

<!-- <div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/588232906?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=b9c56f6007" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="benefits_3D_sfx.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> -->
<!-- <vimeo-player ref="player" video-id="588232906" /> -->
<!-- <vue-vimeo-player video-id="588232906"/> -->
<video-embed src="https://vimeo.com/588232906"></video-embed>

      <br><br>
      <b-nav>
        <b-nav-item :to="to" @click="scrollTo(to)" >
          <h4>
            {{ linklabel }}
          </h4>
        </b-nav-item>
      </b-nav>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section2',
  mounted () {
    this.changeData(require('@/data/content_section2.json'))
  },
  data () {
    return {
      title: '',
      maintext: '',
      video: '',
      poster: '',
      linklabel: '',
      to: ''
    }
  },
  methods: {
    scrollTo: function (hashtag) {
      setTimeout(() => { location.href = hashtag }, 1)
    },
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
      this.video = data.video
      this.poster = data.poster
      this.linklabel = data.linklabel
      this.to = data.to
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-2-content {
  background-image: url("../assets/textures/Raster_blue_smaller.webp");
  z-index: 10;
  margin: auto;
  width: 100%;
  // height: calc(100vh - 76px);
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 10%;
  padding-bottom: 10%;
}
#video-main{
  width: 100%;
  margin-top: 10%;
}
h3 {
  font-style: italic;
  text-align: left;
}
.nav-item .nav-link {
  color: white !important;
  border: none !important;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
    color: #ffffff77 !important;
  }
}
</style>
