import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRellax from 'vue-rellax'

import VueDragDrop from 'vue-drag-drop'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// setup fake backend
import { configureFakeBackend } from '@/scripts/_helpers/fake-backend.js'

import Embed from 'v-video-embed'

Vue.config.productionTip = false

// global register
Vue.use(Embed)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRellax)
Vue.use(VueDragDrop)
configureFakeBackend()

new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {}
  }

}).$mount('#app')
