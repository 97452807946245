<template>
  <section id="section-5">
    <div
      id="section-5-bg"
      v-rellax="{ speed: -4, center: true }"
    ></div>
    <div id="section-5-content">
      <h3>{{ title }}</h3>
      <p>{{ maintext }}</p>
      <br />
      <br />
      <div>
        <b-card-group deck class="justify-content-center">
          <b-card
            v-for="person in persons"
            :key="person.id"
            no-body
            tag="article"
            style="max-width: 20rem; min-width: 18rem"
            class="mb-4 border-0"
          >
            <b-card-img
              :src="person.frontimg"
              alt="Image"
              class="rounded-top"
              :id="person.short"
              @mouseover="hover(person.id)"
              @mouseout="unhover(person.id)"
            ></b-card-img>
            <b-card-body :title="person.name" class="text-left">
              <br />
              <h5>{{ person.profession }}</h5>
            </b-card-body>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section5',
  mounted () {
    this.changeData(require('@/data/content_section5.json'))
  },
  data () {
    return {
      persons: [],
      title: '',
      maintext: ''
    }
  },
  methods: {
    hover (id) {
      document
        .getElementById(this.persons[id].short)
        .setAttribute('src', this.persons[id].backimg)
    },
    unhover (id) {
      document
        .getElementById(this.persons[id].short)
        .setAttribute('src', this.persons[id].frontimg)
    },
    changeData (data) {
      this.title = data.title
      this.maintext = data.maintext
      for (let i = 0; i < data.persons.length; i++) {
        const item = data.persons[i]
        item.id = i
        this.persons.push(item)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-5 {
  background-color: #00060c;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
#section-5-bg {
  background-image: url("../assets/textures/section-5-bg.webp");
  background-size: cover;
  background-position: center;
  height: 150%;
  width: 100vw;
  position: absolute;
  top: -12%;
  transform: translate(-50%, 0);
}
#section-5-content {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
  top: 50%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 15%;
  padding-bottom: 15%;
}
h3 {
  font-style: italic;
  text-align: left;
}
h5 {
  color: white;
  text-align: left;
}
.card {
  background-color: #353535;
  font-style: italic;
  color: #00ffd6;
  border-radius: 1.5%;
}
.rounded-top {
  border-radius: 0%;
  border-top-left-radius: 1.5% !important;
  border-top-right-radius: 1.5% !important;
}
.btn .btn-light {
  border: none !important;

  outline: none !important;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
  }
}
.btn .btn-light:hover {
  color: #212529;
  background-color: #00ffd6;
}
</style>
