<template>
  <section id="footer-content">
    <div id="footer-separator">
      <b-card-group id="carddeck" deck class="justify-content-center">
          <b-card

            no-body
            style="max-width: 80rem; min-width: 20rem"
            class="mb-4 border-0"
          >
            <b-card-img
              src="img/da_team.webp"
              alt="Image"
              class="rounded"
              id="teamcard"
              @mouseover="hover()"
              @mouseout="unhover()"
            ></b-card-img>
            <!-- <b-card-body :title="">
              <br />
              <h5>{{ person.profession }}</h5>
            </b-card-body> -->
          </b-card>
        </b-card-group>
    </div>
    <!-- <div id="footer-img" v-rellax="{ speed: -4, center: true }"></div> -->
    <!-- <div id="spacer"></div> -->
    <div id="footer-contact">
      <b-container>
        <b-row>
          <b-col class="col-12 col-md-4 text-center text-md-left">
            <h5>{{ address.headline }}</h5>
            <b-link :href="address.maplink" target="/blank">
              {{ address.postaladdress.company }}
              <br />
              {{ address.postaladdress.street }}
              <br />
              {{ address.postaladdress.city }}
            </b-link>
          </b-col>
          <b-col class="col-12 col-md-4 text-center text-md-left">
            <h5>{{ contact.headline }}</h5>
            <b-link :href="contact.maillink">{{ contact.maillabel }}</b-link>
            <br />
            <b-link :href="contact.fonlink">{{ contact.fonlabel }}</b-link>
          </b-col>
          <b-col class="col-12 col-md-4 text-center text-md-left">
            <b-link :to="footer.imprintlink"> {{ footer.imprintlabel }} </b-link>
            <br />
            <b-link :to="footer.termslink"> {{ footer.termslabel }} </b-link>
            <br />
            <b-link :to="footer.privacylink"> {{ footer.privacylabel }} </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12  text-center text-md-left">
            {{ footer.copyright }}
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer-Contact',
  mounted () {
    this.changeData(require('@/data/content_contact-footer.json'))
  },
  data () {
    return {
      address: {
        headline: '',
        maplink: '',
        postaladdress: {
          company: '',
          street: '',
          city: ''
        }
      },
      contact: {
        headline: '',
        maillink: '',
        maillabel: '',
        fonlink: '',
        fonlabel: ''
      },
      footer: {
        copyright: '',
        imprintlink: '',
        imprintlabel: '',
        termslink: '',
        termslabel: '',
        privacylink: '',
        privacylabel: ''
      }
    }
  },
  methods: {
    changeData (data) {
      this.address = data.address
      this.address.postaladdress = data.address.postaladdress
      this.contact = data.contact
      this.footer = data.footer
    },
    unhover () {
      document
        .getElementById('teamcard')
        .setAttribute('src', 'img/da_team.webp')
    },
    hover () {
      document
        .getElementById('teamcard')
        .setAttribute('src', 'img/footer-bg-cropped.webp')
    }
  }
}
</script>
<style lang="scss" scoped>
// #footer {
//   background-color: #00060c;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   position: relative;
// }
// #footer-img {
//   background-image: url("../assets/textures/footer-bg.webp");
//   background-size: cover;
//   // height: calc(100vh - 76px);
//   background-position: center;
//   height: 72%;
//   width: 100vw;
//   position: absolute;
//   top: 17%;
//   transform: translate(-50%, 0);
// }
// #spacer {
//   height: calc(100vh - 76px);
// }
#footer-contact {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
  bottom: 0;
  background-color: #00060c;
  margin: auto;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
  padding-bottom: 10px;
}
#carddeck{
  padding: 10%;
}
#footer-separator {
  background-image: url("../assets/textures/Raster_blue_smaller.webp");
  // height: 40vh;
  position: relative;
  z-index: 10;
  // top: -20vh;
}
.col {
  margin-bottom: 32px;
}
h3 {
  text-align: left;
}
a {
  color: white !important;
  border: none !important;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    outline: 0 !important;
    border: none !important;
    color: #ffffff77 !important;
    text-decoration: none;
  }
}
</style>
