<template>
  <div id="app">
    <nav-bar />
    <keep-alive>
      <router-view :key="$route.path" id="router-view" />
    </keep-alive>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data () {
    return {}
  }
  // mounted() {
  //   let observer = new IntersectionObserver(
  //     function (entries, observer) {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
  //           // console.log(entry.target.id, entry.intersectionRatio);

  //           store.dispatch("setActiveHomeViewHash", entry.target.id);
  //         }
  //       });
  //     },
  //     { threshold: [0.5] }
  //   );

  //   observer.observe(document.querySelector("#section-1"));
  //   observer.observe(document.querySelector("#section-2"));
  //   observer.observe(document.querySelector("#section-3"));
  //   observer.observe(document.querySelector("#section-4"));
  //   observer.observe(document.querySelector("#section-5"));
  //   observer.observe(document.querySelector("#section-6"));
  //   observer.observe(document.querySelector("#footer-content"));
  // },
}
</script>

<style lang="scss">
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid"; // fas
@import "~@fortawesome/fontawesome-free/scss/regular"; // far
@import "~@fortawesome/fontawesome-free/scss/brands"; // fab

@import "@/assets/fonts/Sansation/Sansation_Regular.ttf";
@import "@/assets/fonts/Sansation/Sansation_Italic.ttf";
@import "@/assets/fonts/Sansation/Sansation_Bold.ttf";
@import "@/assets/fonts/Sansation/Sansation_Bold_Italic.ttf";
@import "@/assets/fonts/Sansation/Sansation_Light.ttf";
@import "@/assets/fonts/Sansation/Sansation_Light_Italic.ttf";
@import "@/assets/fonts/Helvetica_neue/HelveticaNeueLTStd-Roman.ttf";
@import "@/assets/fonts/Helvetica_neue/HelveticaLTStd-UltraComp.ttf";

@font-face {
  font-family: "Sansation_Regular";
  src: url("/fonts/Sansation/Sansation_Regular.ttf");
}
@font-face {
  font-family: "Sansation_Italic";
  src: url("/fonts/Sansation/Sansation_Italic.ttf");
}
@font-face {
  font-family: "Sansation_Bold";
  src: url("/fonts/Sansation/Sansation_Bold.ttf");
}
@font-face {
  font-family: "Sansation_Bold_Italic";
  src: url("/fonts/Sansation/Sansation_Bold_Italic.ttf");
}
@font-face {
  font-family: "Sansation_Light";
  src: url("/fonts/Sansation/Sansation_Light.ttf");
}
@font-face {
  font-family: "Sansation_Light_Italic";
  src: url("/fonts/Sansation/Sansation_Light_Italic.ttf");
}

@font-face {
  font-family: "HelveticaLTStd-UltraComp";
  src: url("/fonts/Helvetica_neue/HelveticaLTStd-UltraComp.ttf");
}
@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("/fonts/Helvetica_neue/HelveticaNeueLTStd-Roman.ttf");
}

@font-face {
  font-family: "Handwriting";
  src: url("/fonts/jakobs_handwriting_2/JakobsHandwriting2.ttf");
}
#app {
  font-family: "Sansation_Regular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  // background-color: #ff0000;
  // background-image: url("./assets/textures/section-2-bg.jpg");
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#router-view {
  margin-top: 76px;
  // height: calc(100vh - 76px);
}
h1 {
  color: #00ffd6;

  font-size: 4.6rem !important;
  line-height: 1.1;
}
h2 {
  color: #00ffd6;

  font-size: 3.6rem !important;
  line-height: 1.1;
}
h3 {
  color: #00ffd6;
  font-size: 2.6rem !important;
  line-height: 1.1;
}
p {
  color: #00ffd6;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5;
}
body {
  background-color: #00060c !important;
  overflow-x: hidden;
}

.progress .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress .progress-bar {
  background-color: #00ffd6;
}

.progress {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: solid white 1px !important;
}

.modal-open .modal .modal-dialog .modal-content {
  color: #00ffd6;
  background-color: rgb(51, 51, 51);
  font-family: Sansation;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.modal-open .modal .modal-dialog .modal-header {
  border: none;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#carousel-1 {
  .carousel-inner img {
    // max-height: calc(100vh - 152px);
    height: auto;
    max-width: 80% !important;
  }
  .carousel-item {
    position: relative;
    min-height: 20vh;
    width: 90%;
    left: 5%;
    // z-index: 1000;
  }
  // .carousel-item.active {
  //   display: flex;
  //   align-items: center;
  // }
  // .carousel-img {
  //   margin: auto;
  // }
  .carousel-caption {
    width: 100%;
    // height: 30vh;
    // background-color: rgba(255, 0, 0, 0.308);
    // margin: auto;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    // padding-bottom: 30px !important;
    padding: 0;
    // pointer-events: none;
    // height: calc(100vh - 152px);

    //     display: table-cell;
    // vertical-align: middle;
  }
  // .carousel-indicators {
  //   background-color: none;
  // }
  .carousel-control-prev,
  .carousel-control-next {
    // bottom: 0 !important;
    // top: unset !important;
    height: 90%;
    z-index: 100;
  }
  //   .controlBar{
  //   z-index: 4000;
  // }
}
#carousel-2 {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: all;
  // padding: 10px;
  // height: 40vh;
  // .carousel {
  //   height: calc(100vh - 76px);
  // }
  // .carousel-inner {
  //   height: 100% !important;
  // }
  .carousel-inner img {
    max-height: 40vh;
    height: auto;
    width: 80% !important;
    // margin: auto;
    // top: 50%;
  }
  .carousel-item {
    position: relative;
    min-height: 20vh;
    width: 100%;
    // height:fit-content;
  }
  // .carousel-item.active {
  //   display: flex;
  //   align-items: center;
  // }
  // .carousel-img {
  //   margin: auto;
  // }
  .carousel-caption {
    width: 100%;
    // height: 30vh;
    // background-color: rgba(255, 0, 0, 0.308);
    // margin: auto;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    // padding-bottom: 30px !important;
    padding: 0;
    // pointer-events: none;
    // height: 42vh;
  }
  // .carousel-indicators {
  //   background-color: none;
  // }
  .carousel-control-prev,
  .carousel-control-next {
    // bottom: 0 !important;
    // top: unset !important;
    height: 90%;
    z-index: 100;
  }
  //   .controlBar{
  //   z-index: 4000;
  // }
}

#carousel-loading {
  // .carousel-inner img {
  //   width: 100% !important;
  //   // margin: auto;
  //   // top: 50%;
  // }
  // .carousel-inner {
  //   width: 100% !important;
  // }
  // .carousel-item {
  //   width: 100%;
  // }
  .carousel-caption {
    // max-width: 90%;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    padding: 5px !important;
  }
}

@font-face {
  font-family: "Sansation_Regular";
  src: url("./assets/fonts/Sansation/Sansation_Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@media (max-width: 992px) {
  h1 {
    font-size: 3.68rem !important;
  }
  h2 {
    font-size: 3rem !important;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 3.22rem !important;
    font-size: calc(
      2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
  h2 {
    font-size: 2.6rem !important;
    font-size: calc(
      1.86rem + (3.6 - 1.86) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.1 * (1.86rem + (3.6 - 1.86) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
  h3 {
    font-size: 2.08rem !important;
    font-size: calc(
      1.56rem + (2.6 - 1.56) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.3 * (1.56rem + (2.6 - 1.56) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
  h4 {
    font-size: 0.96rem !important;
    font-size: calc(
      1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
  p {
    font-size: 0.96rem !important;
    font-size: calc(
      0.96rem + (1.2 - 0.96) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
}
@media (max-device-width: 600px) {
  #carousel-2 {
    .carousel-caption {
      height: 21vh;
    }
    .carousel-inner img {
      max-height: 20vh;
    }
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #004a4f #00060c;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #00060c;
}

*::-webkit-scrollbar-thumb {
  background-color: #004a4f;
  border-radius: 20px;
  border: 3px solid #00060c;
}
</style>
