<template>
  <div>
    <div id="gallery">
   <!--    <b-button
        variant="primary"
        id="back"
        class="rounded-circle px-2 left-top"
        @click="routerGoBack()"
      >
        <b-icon icon="chevron-left" scale="1"></b-icon>
      </b-button> -->

      <div id="media-carousel" v-if="slides.length > 0">
        <!-- multiple slides -->
        <b-carousel
          id="carousel-1"
          ref="carousel1"
          v-if="slides.length > 1"
          v-model="currentCarouselSlide"
          :interval="0"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
          controls
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide v-for="(item,index) in slides" :key="item.id" id="slide">
            <div id="slide-inner" v-if="item.link">
              <router-link id="slidelink" :to="item.link">
                <video
                  :id="'video-slide'+index"
                  class="video-slide"
                  controls
                  oncontextmenu="return false;"
                  controlsList="nodownload"
                  v-if="item.isVideo"
                  :poster="item.videoPoster ? item.videoPoster :'img/future_cabin.jpg'"
                autobuffer
                  loop
                >
                  <source :src="item.url" type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
                <b-img v-if="!item.isVideo" :src="item.url" alt="" />
              </router-link>
            </div>
            <div id="slide-inner" v-else>
              <video
                  :id="'video-slide'+index"
                  class="video-slide"
                  controls
                  oncontextmenu="return false;"
                  controlsList="nodownload"
                v-if="item.isVideo"
                :poster="item.videoPoster ? item.videoPoster :'img/future_cabin.jpg'"
                autobuffer
                loop
              >
                <source :src="item.url" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
              <b-img
                id="image-slide"
                v-if="!item.isVideo"
                :src="item.url"
                alt=""
              />
            </div>
          </b-carousel-slide>
        </b-carousel>
        <!-- one slide -->
        <b-carousel
          id="carousel-1"
          ref="carousel1"
          v-else
          v-model="currentCarouselSlide"
          :interval="10000"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide v-for="item in slides" :key="item.id">
            <div v-if="item.link">
              <router-link id="slidelink" :to="item.link">
                <video
                  :id="'video-slide'+index"
                  class="video-slide"
                  controls
                  oncontextmenu="return false;"
                  controlsList="nodownload"
                v-if="item.isVideo"
                :poster="item.videoPoster ? item.videoPoster :'img/future_cabin.jpg'"
                autobuffer
                loop
              >
                <source :src="item.url" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
                <b-img v-if="!item.isVideo" :src="item.url" alt="" />
              </router-link>
            </div>
            <div v-else>
              <video
                  :id="'video-slide'+index"
                  class="video-slide"
                  controls
                  oncontextmenu="return false;"
                  controlsList="nodownload"
                v-if="item.isVideo"
                :poster="item.videoPoster ? item.videoPoster :'img/future_cabin.jpg'"
                autobuffer
                loop
              >
                <source :src="item.url" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
              <b-img v-if="!item.isVideo" :src="item.url" alt="" />
            </div>
          </b-carousel-slide>
        </b-carousel>
        <div id="gallery-footer">
          <h5>
            {{ slides[currentCarouselSlide].title }}
          </h5>
          <h6>
            {{ slides[currentCarouselSlide].description }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'Carousel',
  async mounted () {
    // var data
    await fetch('/data/content_reference3.json')
      .then((res) => res.json())
      .then((data) => this.changeData(data))
      .then(
        setTimeout(() => {
          this.$refs.carousel1.setSlide(0)
          // this.onSlideEnd(0)
        }, 1000)
      )
  },
  data () {
    return {
      slides: [],
      currentCarouselSlide: 0
    }
  },
  // activated () {
  //   this.$refs.carousel1.setSlide(0)
  // },
  // deactivated () {
  //   this.$refs.carousel1.setSlide(0)
  // },
  methods: {
    routerGoBack () {
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    },
    changeData: function (data) {
      for (let i = 0; i < data.slides.length; i++) {
        const item = data.slides[i]
        item.id = i
        this.slides.push(item)
      }
    },
    onSlideStart (currentCarouselSlide) {
      if (!this.slides[currentCarouselSlide].isVideo) {
        var video = document.getElementById('video-slide')
        if (video) {
          video.pause()
          video.currentTime = 0
        }
      }
    },
    onSlideEnd (currentCarouselSlide) {
      if (this.slides[currentCarouselSlide].isVideo) {
        var video = document.getElementById('video-slide' + currentCarouselSlide)
        if (video) {
          video.currentTime = 0
          video.play()
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
#media-carousel {
  top: 76px;
  // height: calc(100vh - 76px);
}
// #slide {
//   // display: block;
//   //  margin: auto;
// }
// #image-slide {
// }
#slide-inner {
  // height: calc(100vh - 156px);
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
}
.video-slide {
  z-index: 1000;
  pointer-events: all;
  // width: auto;
  max-width: 80% !important;

  max-height: calc(100vh - 152px);
  // min-height: 50px;
  height: auto;
  margin: auto;

  // max-height: 80%;
  // top: 50%;
  left: 50%;
  // transform: translateX(-50%);
}
#gallery {
  // background-image: url("../assets/textures/Raster_blue_smaller.webp");
  background-color: rgba(0, 0, 0, 0.45);
  padding-top: 37px;
}
#gallery-footer {
  height: 76px;
  padding-top: 10px;
  // background-color: rgba(0, 0, 0, 0.7);
}
#back {
  z-index: 2000;
}
.left-top {
  position: absolute;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
}
#carousel-1 {
  text-align: center !important;
  transition: height 3s ease-in-out;
}
::v-deep p {
  text-align: center !important;

  padding-left: 20%;
  padding-right: 20%;
}
</style>
